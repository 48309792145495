<template>
  <div id="register-modal" class="modal fade modal-forms" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">

      <!-- BOAS VINDAS -->
      <div class="modal-content" id="signup-modal-content" v-if="exibeBoasVindas">
        <div class="modal-header">
          <h4 class="modal-title text-center mt-3 mb-1"> Bem vindo!</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>                
        <div class="modal-body text-center">
          <p class="text-center mb-5">
            <big>
              Conta criada com sucesso.<br/>
              Após confirmar seu registro com o link  <br/>
              recebido por e-mail, faça seu login:
            </big>
          </p>
          <a href="#" v-on:click="showLogin" class="btn digimais-cdc-button abre-modal">Acesse seu contrato</a>
        </div>
      </div>

      <!-- FORMULÁRIO -->
      <div class="modal-content" id="signup-modal-content" v-if="exibeForm">
        <div class="modal-header">
          <h4 class="modal-title">Cadastrar</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        </div>                
        <div class="modal-body">
          <p class="alert alert-danger p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
          <form method="post" id="Signup-Form" role="form">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"><i class="icon fas fa-address-card"></i></div>
                <!-- <input name="cpf" id="cpf" class="form-control input-lg cpf-input" placeholder="Digite seu CPF" required data-parsley-minlength="11" data-parsley-trigger="keyup"> -->
                <the-mask 
                  class="form-control input-lg cpf-input"
                  placeholder="Digite seu CPF/CNPJ"
                  required 
                  v-model="cpf" 
                  :mask="['###.###.###-##', '##.###.###/####-##']" 
                />
              </div>                      
            </div>
            <div class="form-group">
              <div class="input-group">
                  <div class="input-group-addon"><i class="icon fas fa-calendar-alt"></i></div>
                  <!-- <input type="text" class="form-control input-lg date-input" id="dataNascimento" name="dataNascimento" placeholder="Data de nascimento" required /> -->
                <the-mask 
                  class="form-control input-lg date-input" 
                  mask="##/##/####" 
                  placeholder="Data de Nascimento"
                  required
                  v-model="dataNascimento" 
                />
              </div>                      
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"><i class="icon fas fa-at"></i></div>
                <!-- <input name="email" id="email" type="email" class="form-control input-lg" placeholder="Digite seu email" required data-parsley-type="email" data-parsley-trigger="keyup"> -->
                <input
                  name="email"
                  id="email"
                  type="email"
                  class="form-control input-lg"
                  placeholder="Digite seu email"
                  required
                  data-parsley-type="email"
                  data-parsley-trigger="keyup"
                  v-model="email"
                />
              </div>                     
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"><i class="icon fas fa-at"></i></div>              
                <input
                  name="confirm-email"
                  id="confirm-email"
                  type="email"
                  class="form-control input-lg"
                  placeholder="Confirme seu email"
                  required
                  data-parsley-type="email"
                  data-parsley-trigger="keyup"
                  v-model="confirmaEmail"
                />
              </div>                     
            </div>            
            <div class="form-group mt-5">
              <div class="input-group">
                  <div class="input-group-addon"><i class="icon fas fa-lock"></i></div>
                <!-- <input name="password" id="passwd" type="password" class="form-control input-lg" placeholder="Digite sua senha" required data-parsley-minlength="6" data-parsley-trigger="keyup"> -->
                <input
                  name="senha"
                  type="password"
                  class="form-control input-lg"
                  placeholder="Digite sua senha"
                  required
                  data-parsley-minlength="6"
                  data-parsley-trigger="keyup"
                  v-model="senha"
                />
              </div>                      
            </div>
            <div class="form-group">
              <div class="input-group">
                  <div class="input-group-addon"><i class="icon fas fa-lock"></i></div>
                <!-- <input name="password" id="confirm-passwd" type="password" class="form-control input-lg" placeholder="Confirme sua senha" required data-parsley-equalto="#passwd" data-parsley-trigger="keyup"> -->
                <input
                  name="password"
                  id="confirm-passwd"
                  type="password"
                  class="form-control input-lg"
                  placeholder="Confirme sua senha"
                  required
                  data-parsley-equalto="#passwd"
                  data-parsley-trigger="keyup"
                  v-model="confirmaSenha"
                />
              </div>                      
            </div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="visible"
              sitekey="6LefPNIUAAAAANzmcG-ZK1_FCq7JKnlof0ffaHAn"
            >
            </vue-recaptcha>
            <!-- <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal" href="forms/cadastro-sucesso-form.html" data-remote="false" data-target="#register-modal">Criar conta</button> -->
            <button
              type="submit"
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              data-remote="false"
              data-target="#register-modal"
              v-on:click="cadastrar"
            >
              <div class="spinner-border" role="status" v-if="exibeLoading">
                <span class="sr-only"></span>
              </div>
              Criar conta
            </button>
          </form>
        </div>
        <!-- <div class="modal-footer">
          <p>Já tem cadastro? <a href="forms/login-form.html" data-remote="false" data-dismiss="modal" data-toggle="modal" data-target="#login-modal" class="abre-modal">Acesse seu contrato</a></p>
        </div> -->
        <div class="modal-footer">
          <p>
            Já tem cadastro?
            <a
              href="login-form"
              v-on:click="showLogin"
              class="abre-modal"
            >Acesse seu contrato</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
// import Acesso from "../api_portal_auto_atendimento/src/model/Acesso";
import moment from 'moment';
import EventBus from '../event-bus';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "CadastroModal",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      errors: [],
      processing: false,
      cadastroFinalizado: false,
      cadastroAtivo: false,
      cpf: "",
      // contrato: "",
      // prestacao: "",
      email: "",
      confirmaEmail: "",
      dataNascimento: "",
      // telefone: "",
      senha: "",
      token: "",
      confirmaSenha: ""
    };
  },
  mounted(){
    var self = this;
    EventBus.$on('eventShowCadastro', function() {
      self.show();
    });
  },
  computed: {
    exibeLoading:function() {
      return this.processing;
    },
    exibeForm: function() {
      return !this.cadastroFinalizado;
    },
    exibeBoasVindas: function() {
      return this.cadastroFinalizado;
    },
    exibeDataNascimento: function() {
      return this.cpf.length <= 11;
    }
  },
  methods: {
    showLogin: function(event){
      if (event) {
        event.preventDefault();
      }
      $("#register-modal").modal("hide");
      EventBus.$emit('eventShowLogin');
    },
    show: function(event){
      if (event) {
        event.preventDefault();
      }
      this.cadastroFinalizado = false;
      this.cadastroAtivo = false;
      this.errors = [];
      this.cpf = '';
      this.email = '';
      this.confirmaEmail = '';
      this.dataNascimento = '';
      this.senha = '';
      this.confirmaSenha = '';
      $("#register-modal").modal("show");
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.token = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.token = '';
      this.$refs.recaptcha.reset();
    },
    cadastrar(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;

      this.errors = [];

      if (this.cpf.length == 0) {
        this.errors.push("Informe o seu CPF.");
      } else if (!(this.cpf.length == 11 || this.cpf.length == 14)) {
        this.errors.push("Informe um CPF válido.");
      }

      if (this.cpf.length == 11) {
        if (this.dataNascimento.length == 0) {
          this.errors.push("Informe o sua Data de Nascimento.");
        } else if (!this.validDate(moment(this.dataNascimento, 'DDMMYYYY').format("DD/MM/YYYY"))) {
          this.errors.push("Informe uma data válida");
        }
      }

      if (this.email.length == 0) {
        this.errors.push("Informe o seu E-mail.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Informe um e-mail válido");
      }

      if (this.confirmaEmail.length == 0) {
        this.errors.push("Confirme o seu E-mail.");
      } else if (this.email != this.confirmaEmail) {
        this.errors.push("Verifique a confirmação de email.");
      }

      if (this.senha.length == 0) {
        this.errors.push("Informa sua senha.");
      } else if (this.senha != this.confirmaSenha) {
        this.errors.push("Verifique a confirmação da senha.");
      } else if (!this.validPassword(this.senha)) {
        this.errors.push(
          "A senha deve possuir entre 6 e 8 dígitos,  conter pelo ao menos uma letra maiúscula, uma minúscula e número."
        );
      }

      if (this.token.length == 0) {
        this.errors.push("Use o reCaptcha para demostrar que você não é um robô.");
      }

      if (this.errors.length == 0) {
        let acessoApi = new ApiPortalAutoAtendimento.AcessoApi();

        let opts = {};
        if (this.cpf.length == 11) {
          opts.dataNascimento = moment(this.dataNascimento, 'DDMMYYYY').format("YYYY-MM-DD");
        } 

        acessoApi.cadastroAcesso(this.cpf, this.email, this.confirmaEmail, this.senha, this.token, opts, (error, data, response) => {
          this.processing = false;
          if (!error) {
            this.cadastroFinalizado = true;
          } else {
            this.$refs.recaptcha.reset();
            if (response && response.text) {
              try {
                let listOfErrors = JSON.parse(response.text);
                listOfErrors.forEach(error => {
                  this.errors.push(error.message);
                });
              } catch (e) {
                this.errors.push("Erro ao realizar cadastro.");
              }
            } else {
              this.errors.push("Erro ao realizar cadastro.");
            }
          }
        });

        acessoApi.verificarCadastroAtivo(this.cpf, opts, (error, data, response) => {
          this.processing = false;
          if (!error) {
            this.cadastroAtivo = true;
          } else {
            if (response && response.text) {
              try {
                let listOfErrors = JSON.parse(response.text);
                listOfErrors.forEach(error => {
                  this.errors.push(error.message);
                });
              } catch (e) {
                this.errors.push("Erro ao verificar o cadastro.");
              }
            } else {
              this.errors.push("Erro ao verificar o cadastro.");
            }
          }
        });
      } else {
        this.processing = false;
      }
    },
    validPassword: function(value) {
      let re = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,8})/g;
      return re.test(value);
    },
    validEmail: function(value) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    },
    validDate: function(value) {
      var re = /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/g;
      return re.test(value);
    }
  }
};
</script>

  <style>
</style>