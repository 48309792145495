<template>
  <div id="digimais-cdc-login">
    <nav class="navbar navbar-expand-md fixed-top bg-transparent navbar-light">
      <div class="collapse navbar-collapse" id="main-menu"></div>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#main-menu"
        aria-controls="main-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
    </nav>

    <!-- Modals -->
    <LoginModal></LoginModal>
    <LoginFastAccessModalCompleto />

    <CadastroModal></CadastroModal>

    <NovaSenhaModal></NovaSenhaModal>

	<section class="novo-main">
      <main class="row g-0 m-zero">
        <div class="col-xl-7 col-lg-7 position-relative py-5">
          <div class="row">
            <div class="col-md-7 col-sm-12 offset-md-3 offset-sm-0">
              <div class="novo-main-box">
                <h1><strong>Portal DIGI+ Fácil</strong></h1>
                <P>Acesse para emitir 2° via de boleto, quitação, extrato e muito mais.</P>
                <div class="novo-main-box-btn">
                  <a href="#" class="digimais-cdc-button color-red novo-btn" data-remote="false" data-toggle="modal"
                    data-target="#register-modal">FAÇA O SEU CADASTRO</a>
                  <p class="novo-main-link">
                    <a href="#" data-remote="false" data-toggle="modal" data-target="#login-modal">
                      Já é cadastrado? Clique aqui.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-5 col-lg-5 pe-lg-5 p-zero">
          <div class="d-flex h-100 pe-xl-4">
            <img src="../assets/img/img_cadastro.png" alt="">
          </div>
        </div>
          
      </main>
    </section>
    
    <section data-anchor="conta-digimais" class="conteudo">
      <div class="container">
        <h3>Encontre o que você precisa em um único lugar!</h3>
        <div class="lista-itens">

            <div>
              <div class="lista-itens-new-bloco">
                <a 
                  href="forms/login-form.html"
                  data-remote="false"
                  data-toggle="modal"
                  data-target="#login-modal"
                >
                  <div class="lista-itens-new-bloco-intern">
                    <h4>Visualizar <span><br> Contrato</span></h4>
                    <p>Consulte seu contrato! Clique aqui e fique por dentro de todas as cláusukas contratuais e todos os detalhes do nosso acordo.</p>
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div class="lista-itens-new-bloco">
                <a 
                  href="forms/login-form.html"
                  data-remote="false"
                  data-toggle="modal"
                  data-target="#login-modal"
                >
                  <div class="lista-itens-new-bloco-intern">
                    <h4>2° via <span><br> de boletos</span></h4>
                    <p>Fique em dia com suas parcelas! Emita aqui a segunda via do seu boleto bancário do jeito mais rápido, prático e sinmples!</p>
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div class="lista-itens-new-bloco">
                <a 
                  href="forms/login-form.html"
                  data-remote="false"
                  data-toggle="modal"
                  data-target="#login-modal"
                >
                  <div class="lista-itens-new-bloco-intern">
                    <h4>Carnê <span><br> Digital</span></h4>
                    <p>Tenha acesso ao seu carnê digital e verifique o pagamento de suas parcelas onde e quando quiser.</p>
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div class="lista-itens-new-bloco">
                <a 
                  href="forms/login-form.html"
                  data-remote="false"
                  data-toggle="modal"
                  data-target="#login-modal"
                >
                  <div class="lista-itens-new-bloco-intern">
                    <h4>Extrato de <span><br> Pagamentos</span></h4>
                    <p>Acompanhe o histórico do seu parcelamento e confira o andamento do seu financiamento com um clique!</p>
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div class="lista-itens-new-bloco">
                <a 
                  class="btn-acesso abre-modal"
                  href="forms/login-form.html"
                  data-remote="false"
                  data-toggle="modal"
                  data-target="#login-modal"
                >
                  <div class="lista-itens-new-bloco-intern">
                    <h4>Liquidação <span><br> Antecipada</span></h4>
                    <p>Faça o cálculo, antecipe suas parcelas e quite seu financiamento antes do vencimento regular.</p>
                  </div>
                </a>
              </div>
            </div>

            <div>
              <div class="lista-itens-new-bloco">
                <a 
                  href="forms/login-form.html"
                  data-remote="false"
                  data-toggle="modal"
                  data-target="#login-modal"
                >
                  <div class="lista-itens-new-bloco-intern">
                    <h4>Declaração <span><br> Anual</span></h4>
                    <p>Tenha acesso a sua declaração anual de quitação de débito e consulte quando quiser!</p>
                  </div>
                </a>
              </div>
            </div>


            <div class="unica">
              <div class="lista-itens-new-bloco-grande">
                <div class="col-xl-5 col-lg-5 pe-lg-5 p-zero img" >
                  <div class="d-flex h-100 pe-xl-4" >
                    <img src="../assets/img/Box_2via.png" alt="">
                  </div>
                </div>
                <a 
                      href="forms/login-form-Fast-Completo.html" 
                      data-remote="false"
                      data-toggle="modal"
                      data-target="#login-modal-Fast-Completo"
                    >
                      <div class="lista-itens-new-bloco-grande-intern">
                        <h4>2ª via<span><br>Mais fácil</span></h4>
                        <p>Gere o carnê ou emita a segunda via de boleto do financiamento 
                        apenas com o CPF. Antecipe seu pagamento. regularize parcelas com até 10 dias de atraso e liquide seu contrato de forma mais descomplicada!</p>
                      </div>
                </a>
              </div>
            </div>
        </div>
      </div>
    </section>
    <section class="dr-fraude">
      <div class="dr-fraude-centered">
        <a
          class="btn-acesso abre-modal"
          href="https://www.bancodigimais.com.br/dr-fraude"
          data-remote="true"
          target="_blank"
        >
          <img
            src="../assets/img/drFraude2.png"
          />
        </a>
        </div>
    </section>
    <section class="novo-cadastro">
      <div class="novo-cadastro-bloco">
        <h3>Veja como é fácil acessar o seu contrato ou a 2° via do seu boleto:</h3>

          <ul class="novo-cadastro-itens">
            <li><div class="container"><span class="novo-click"></span><p>Click no botão<br> FAÇA SEU CADASTRO.</p></div></li>
            <li><div class="container"><span class="novo-form"></span><p> Preencha o formulário com seus dados <span class="italic"><br>(Os mesmos do financiamento).</span></p></div></li>
            <li><div class="container"><span class="novo-email"></span> <p>Verifique o e-mail de ativação que foi enviado para você.</p></div></li>
          </ul>
      </div>
      <div class="novo-cadastro-bloco">
          <h3>Pronto!<br/>Basta fazer o login com seu CPF/CNPJ e senha criada.</h3>
      </div>
      
    </section>
    <section class="conteudo-mobile novo-financimento">
        <div class="container conteudo-3">
          <div class="row mobile-reverse">
            <div class="col-12 col-lg-8 centraliza">
              <div class="row-mobile">
                <img src="../assets/img/generic_car4.png" />
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-4">
              <div class="center-block">
                <h3 class="centraliza"><strong>Aproveite todas<br>as facilidades</strong></h3>
                <h2 class="centraliza">O Digi+ Fácil oferece os<br>melhores serviços para<br>o seu financiamento.</h2>
                <div class="novo-cadastro-block-btn">
                  <a href="#" data-remote="false" data-toggle="modal" data-target="#register-modal"
                    class="digimais-cdc-button color-red">FAÇA O SEU CADASTRO</a>
                  <p class="novo-cadastro-link">
                    <a href="#" data-remote="false" data-toggle="modal" data-target="#login-modal">
                      Já é cadastrado? Clique aqui.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="f-menu">
            <ul class="list-unstyled">
              <li>
                <a target="_blank" href="https://www.bancodigimais.com.br/">Aviso Importante</a>
              </li>
              <li>
                <a target="_blank" href="https://www.bancodigimais.com.br/politica-de-privacidade">Política de Privacidade</a>
              </li>
              <li>
                <a target="_blank" href="https://www.bancodigimais.com.br/seguranca-de-dados">Segurança da Informação</a>
              </li>
            </ul>
          </div>
        </div>
    </section>

    <div id="footer">
      <div class="container">
        <div class="f-contact">
          <div class="central-rel">
          Central de Relacionamento - Segunda a sexta das 8h às 20h e sábados das 8h às 14h (exceto
feriados) | Capitais e regiões metropolitanas 4020 3300 | Outras localidades 0800 646 7676 | 
WhatsApp 11 4020 3300 | SAC (Reclamações, dúvidas e sugestões) atendimento 24h 0800
646 3700 | Ouvidoria - segunda a sexta das 10h às 16h (exceto feriados) 0800 646 7600 | 51 3284 3100 | 
Deficientes auditivos ou de fala WhatsApp 11 4020 3300 
          </div>
          <div class="redes">
            <div>
              <a
                href="https://www.soudigimais.com.br/home"
                class="logo-footer"
                target="_blank"
                ><img
                  src="../assets/img/logo_header.png"
                  title="A conta que todos podem ter"
                  alt="Digi+ Banco Digital"
              /></a>
            </div>
            <div class="redes-url">
              <a href="https://www.facebook.com/bancodigimais" target="_blank"
                ><img src="../assets/img/icon-facebook.png" />
              </a>
              <a href="https://www.instagram.com/bancodigimais" target="_blank"
                ><img src="../assets/img/icon-instagram.png" />
              </a>
              <a href="https://twitter.com/bancodigimais" target="_blank"
                ><img src="../assets/img/icon-twitter.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="sub-footer">
        <div class="container">
          <p>
            © 2022 Banco Digi+ Todos os direitos reservados
          </p>
          <p>
            Banco Digimais | CNPJ: 92.874.270/0003-01 Rua Cubatão, 320 4º andar - Paraíso - São Paulo/SP
          </p>
        </div>
      </div>
    </div>
    <!-- end footer -->
  </div>
</template>

<script>
import EventBus from "../event-bus";
import LoginModal from "../components/LoginModal";
import CadastroModal from "../components/CadastroModal";
import NovaSenhaModal from "../components/NovaSenhaModal";
import LoginFastAccessModalCompleto from "../components/LoginFastAccessModalCompleto";

export default {
  name: "home",
  components: {
    LoginModal,
    LoginFastAccessModalCompleto,
    CadastroModal,
    NovaSenhaModal,
  },
  mounted() {
    var modal_needfix = true;
    $(".modal").on("shown.bs.modal", function () {
      var modalscope = $(this);
      modalscope.css({ paddingRight: "" });
      if (modal_needfix) {
        window.addEventListener("resize", function () {
          requestAnimationFrame(function () {
            // be sure to act after the pluggin script
            modalscope.css({ paddingRight: "" });
          });
        });
        modal_needfix = false;
      }
    });

    const self = this;
    setTimeout(function () {
      var element = document.getElementById("popupModal");

      if (element != null) {
        if (!self.hasClass(element, "openModal")) {
          element.classList.add("openModal");
        }
      }
    }, 1000);
  },
  methods: {
    showLogin(event) {
      if (event) {
        event.preventDefault();
      }
      EventBus.$emit("eventShowLogin");
    },
    showCadastro: function (event) {
      if (event) {
        event.preventDefault();
      }
      EventBus.$emit("eventShowCadastro");
    },
    // showLoginFast: function (event) {
    //   if (event) {
    //     event.preventDefault();
    //   }
    //   EventBus.$emit("eventShowLoginFast");
    // },
  },
};
</script>
<style>
</style>
