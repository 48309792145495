<template>
  <div id="login-modal-Fast-Completo" class="modal fade modal-forms" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" v-if="!!this.cadastroAtivo">Acesse seu contrato</h4>
          <h4 class="modal-title" v-if="!this.cadastroAtivo">Cadastro Pendente</h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>   
        <div class="modal-body">
          

        <div class="alert alert-primary" role="alert" v-if="this.emailEnviado">
          E-mail enviado com sucesso.
          Verifique seu email e ative seu cadastro.
        </div>
          <p class="alert alert-danger p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
          <p class="text-decoration-none" v-if="!this.cadastroAtivo">
            Você já tem uma confirmação de cadastro pendente. <br/>
            Deseja reenviar? 
          </p>
          <form method="post" id="Login-Form-Fast-Completo" role="form" action="#">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"><i class="icon fas fa-user"></i></div>
                <the-mask
                  class="form-control input-lg cpf-input"
                  placeholder="Digite o CPF/CNPJ do cliente"
                  required
                  v-model="cpf"
                  :mask="['###.###.###-##', '##.###.###/####-##']"
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"><i class="icon fas fa-user"></i></div>
                <input name="usuario" 
                       id="Usuario" 
                       type="text" 
                       class="form-control input-lg" 
                       placeholder="Usuário do Lojista" 
                       required 
                       data-parsley-type="string" 
                       data-parsley-trigger="keyup" 
                       v-model="usuario">
              </div>
            </div>
            <div class="form-group" v-if="!exibeConfEmail">
                <div class="input-group">
                  <div class="input-group-addon"><i class="icon fas fa-lock"></i></div>
                  <input
                    name="password"
                    type="password"
                    class="form-control input-lg"
                    placeholder="Senha do Lojista"
                    required
                    data-parsley-minlength="6"
                    data-parsley-trigger="keyup"
                    v-model="senha"
                  />
                </div>
            </div>

            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="visible"
              sitekey="6LefPNIUAAAAANzmcG-ZK1_FCq7JKnlof0ffaHAn"
            >
            </vue-recaptcha>

            <button
              type="submit"
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              v-on:click="autenticar"
              v-if="!!this.cadastroAtivo"
            >
              <div class="spinner-border" role="status" v-if="this.processing">
                <span class="sr-only"></span>
              </div>
              Entrar
            </button>
            <button 
              type="submit" 
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              v-if="!this.cadastroAtivo"
              v-on:click="autenticarCadastroPendente"
            >
              <div class="spinner-border" role="status" v-if="this.processing">
                <span class="sr-only"></span>
              </div>
              Enviar Confirmação
            </button>

          </form>

        </div>
        <div class="modal-footer">
          <p>
            <a
              href="#"
              class="abre-modal mx-2"
              v-on:click="showCadastro"
            >Cadastrar</a> |
            <a
              href="#"
              v-on:click="showNovoLogin"
              class="abre-modal mx-2"
            >Esqueci minha senha</a>
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
import AuthServiceContext from "../services/AuthService";
import EventBus from '../event-bus';

import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "LoginFastAccessModalCompleto",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      authService: AuthServiceContext.getInstance(),
      errors: [],
      usuario:"",
      cpf: "",
      senha: "",
      token: "",
      email:'',
      confirmaEmail:'',
      processing: false,
      cadastroAtivo: true,
      emailEnviado: false,
    };
  },
  props: {

  },
  mounted() {
    EventBus.$on('eventShowLoginFast', () => this.preventDefault.show());
  },
  computed: {
    exibeLoading:function() {
      return this.processing;
    },
    exibeConfEmail: function() {
      return !this.cadastroAtivo;
    },
    exibeMensagemEnvioEmail: function() {
      return this.emailEnviado;
    }
  },
  methods: {
    show: function(event){
      this.errors = [];
      this.cpf = '';
      this.senha = '';
      this.token = '';
      this.usuario = '';
      $("#login-modal-Fast-Completo").modal("show");
    },
    showNovoLogin: function(event) {
      if (event) 
        event.preventDefault();
      
      $("#login-modal-Fast-Completo").modal("hide");
      EventBus.$emit('eventShowNovoLogin');
    },
    showCadastro: function(event) {
      if (event) 
        event.preventDefault();
      $("#login-modal-Fast-Completo").modal("hide");
      EventBus.$emit('eventShowCadastro');
    },
    onCaptchaVerified: function (recaptchaToken) {
      this.token = recaptchaToken;
    },
    onCaptchaExpired: function () {
      this.token = '';
      this.$refs.recaptcha.reset();
    },
    parserException(response, defaultMessage = 'Erro ao realizar operação.') {
      if (response && response.text) {
        try {
          let listOfErrors = JSON.parse(response.text);
          listOfErrors.forEach(error => {
            if (error.code && error.code == 901) {
              this.contratoEmAtraso = true;
            }
            this.errors.push(error.message);
          });
        } catch (e) {
          this.errors.push(defaultMessage);
        }
      } else {
        this.errors.push(defaultMessage);
      }
      if (this.errors.length > 0)
        this.simulacao = {};
      
      if (this.contratoEmAtraso) 
        this.errors = [];
    },
    autenticar(event) {
      if (event) 
        event.preventDefault();
      
      if (this.processing) 
        return;
      
      this.errors = [];

      if (this.cpf.length == 0) 
        this.errors.push("Informe o seu CPF.");
      else if (!(this.cpf.length == 11 || this.cpf.length == 14)) 
        this.errors.push("Informe um CPF válido.");

      if (this.usuario.length == 0)
        this.errors.push("Informe o Usuário do Lojista.");

      if (this.senha.length == 0)
        this.errors.push("Informe a senha do Lojista.");

      if (this.token.length == 0) 
        this.errors.push("Use o reCaptcha para demostrar que você não é um robô.");
      
      if (this.errors.length == 0) {
        
      this.processing = true;

      this.authService.authenticateCompleto(this.usuario, this.senha, this.token, this.cpf).then(result => {
        if (result) {
            this.cadastroAtivo = true;

            let userData = this.authService.userData();
            let empresa = userData.Empresa; 
            let situacao = "A"; 
            let contratosApi = new ApiPortalAutoAtendimento.ContratoApi();
            let lojistaUsr = userData.LojistasLojas[0].Item1;
            contratosApi.consultaContratosPorCliente(empresa, this.cpf, situacao, (error, data, response) => {
              this.primeiraConsulta = true;
              if (error) {
                this.errorMessage = 'Erro ao obter contratos.';
              } else {
                this.cadastroAtivo = true;
                $("#login-modal-Fast-Completo").modal("hide");
                this.$router.push("parcelas");
              }
            });
            this.processing = false;
          } 
          this.processing = false;
        }).catch(error => {
          if(error.response && error.response.status == 400) {
            let acessoApi = new ApiPortalAutoAtendimento.AcessoApi();
            acessoApi.verificarCadastroInativo(this.cpf, (error, inativo, response) => {  
              if(!error) {
                if (inativo) {
                  this.cadastroAtivo = false;
                } else {
                  this.errors.push("CPF/CPNJ ou senha inválidos.");
                }
              } else {
                if (response && response.text) {
                  try {
                    let listOfErrors = JSON.parse(response.text);
                    listOfErrors.forEach(error => {
                      this.errors.push(error.message);
                    });
                  } catch (e) {
                    this.errors.push("Erro ao verificar o cadastro.");
                  }
                } else {
                  this.errors.push("Erro ao verificar o cadastro.");
                }
                this.cadastroAtivo = true;
                this.processing = false;
              }
              this.processing = false;
            });
            
          } else {
            this.errors.push("Erro ao realizar login.");
          }
          this.processing = false;
        });
      }
    },
    autenticarCadastroPendente(event)
    {
      if (event) 
        event.preventDefault();
      
      if (this.processing) 
        return;
      
      this.processing = true;

      this.errors = [];

      if (this.cpf.length == 0) 
        this.errors.push("Informe o seu CPF.");
      else if (!(this.cpf.length == 11 || this.cpf.length == 14)) 
        this.errors.push("Informe um CPF válido.");
      

      if (this.errors.length == 0) {
        if (this.email != '' && this.confirmaEmail != '' && this.email == this.confirmaEmail) 
        {
          let acessoApi = new ApiPortalAutoAtendimento.AcessoApi(); 
        
          acessoApi.reenviarEmail(this.cpf, this.email, (error, data, response) =>{
            if (error) {
              if (response && response.text) {
                try {
                  let listOfErrors = JSON.parse(response.text);
                  listOfErrors.forEach(error => {
                    this.errors.push(error.message);
                  });
                } catch (e) {
                  this.errors.push("Erro ao reenviar email.");
                }
              } else {
                this.errors.push("Erro ao reenviar email.");
              }
            } else {
              this.processing = false;
              this.emailEnviado = true;
              this.cadastroAtivo = true;
              this.senha = '';
              this.cpf = '';
            }
          });
        }
      }
    }
  }
};
</script>