<template>

  <div id="password-reset-modal" class="modal fade modal-forms" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">

    <!-- Mensagem -->
    
    <div class="modal-content" id="forgot-password-modal-content" v-if="exibeConfirmacao">
      <div class="modal-header pb-0">
        <h4 class="modal-title">E-mail enviado!</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>        
      <div class="modal-body">
        <p class="text-center">
          Verifique seu e-mail e utilize o link recebido para redefinir sua senha.
        </p>
        <!-- <form method="post" id="Forgot-Password-Form2" role="form">
          <div class="form-group">
              <div class="input-group">                  
                <div class="input-group-addon"><i class="icon fas fa-mobile-alt"></i></div>
                <input class="form-control input-lg" id="codigo" name="codigo" type="text" placeholder="Código" required="required"  data-parsley-type="alphanum">
              </div>                     
          </div>
          
        </form> -->
        <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal" v-on:click="fechar">Fechar</button>
      </div>        
      <div class="modal-footer">
        <p>Lembrou sua senha? <a href="#" class="abre-modal" v-on:click="showLogin">Acesse seu contrato</a></p>
        
      </div>
      
    </div> 

    <!-- FORM -->
    <div class="modal-content" id="forgot-password-modal-content" v-if="exibeForm">
      <div class="modal-header pb-0">
        <h4 class="modal-title">Esqueci minha senha</h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </div>        
      <div class="modal-body">
        <p class="text-center">Digite seu CPF/CNPJ e seu e-mail para receber um e-mail de redefinição de senha.</p>
        <form method="post" id="Forgot-Password-Form" role="form">
          <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon">
                  <i class="icon fas fa-address-card"></i>
                </div>
                <the-mask 
                  class="form-control input-lg cpf-input"
                  placeholder="Digite seu CPF/CNPJ"
                  required 
                  v-model="cpf" 
                  :mask="['###.###.###-##', '##.###.###/####-##']" 
                />
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-addon"><i class="icon fas fa-at"></i></div>
                <input
                  type="text"
                  class="form-control input-lg"
                  name="email"
                  placeholder="E-mail"
                  v-model="email"
                />
              </div>
            </div>
          <!-- <button type="submit" class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal" href="forms/esqueci-senha-form2.html" data-remote="false" data-target="#password-reset-modal">Enviar SMS</button> -->
          <button
              type="submit"
              class="btn digimais-cdc-button btn-block btn-lg mt-4 abre-modal"
              data-remote="false"
              data-target="#password-reset-modal"
              v-on:click="soliticitar"
            >
              <div class="spinner-border" role="status" v-if="exibeLoading">
                <span class="sr-only"></span>
              </div>
              Solicitar
          </button>
          <br/><br/>
          <p class="alert alert-danger p-2" v-if="errors.length">
            <ul>
              <li
                v-for="(error, index) in errors"
                v-bind:key="index"
              >{{ error }}</li>
            </ul>
          </p>
        </form>
      </div>        
      <div class="modal-footer">
        <p>Lembrou sua senha? <a href="forms/login-form.html" data-remote="false" data-dismiss="modal" data-toggle="modal" data-target="#login-modal" class="abre-modal">Acesse seu contrato</a></p>
      </div>
      
    </div>      

    </div>
  </div>

  
</template>

<script>
import ApiPortalAutoAtendimento from "../api_portal_auto_atendimento/src/index";
// import Acesso from "../api_portal_auto_atendimento/src/model/Acesso";
import EventBus from '../event-bus';

export default {
  name: "NovaSenha",
  data() {
    return {
      errors: [],
      processing: false,
      solicitacaoRealizada: false,
      cpf: "",
      email: ""
    };
  },
  mounted() {
    var self = this;
    EventBus.$on('eventShowNovoLogin', function() {
      self.show();
    });
  },
  computed: {
    exibeLoading:function() {
      return this.processing;
    },
    exibeForm: function() {
      return !this.solicitacaoRealizada;
    },
    exibeConfirmacao: function() {
      return  this.solicitacaoRealizada;
    }
  },
  methods: {
    fechar: function(event) {
      if (event) {
        event.preventDefault();
      }
      $("#password-reset-modal").modal("hide");
    },
    show: function(event){
      if (event) {
        event.preventDefault();
      }
      this.errors = [];
      this.cpf = '';
      this.email = '';
      this.solicitacaoRealizada = false;
      $("#password-reset-modal").modal("show");
    },
    showLogin: function(event){
      if (event) {
        event.preventDefault();
      }
      $("#password-reset-modal").modal("hide");
      EventBus.$emit('eventShowLogin');
    },
    soliticitar: function(event) {
      if (event) {
        event.preventDefault();
      }
      if (this.processing) {
        return;
      }
      this.processing = true;

      this.errors = [];

      if (this.cpf.length == 0) {
        this.errors.push("Informe o seu CPF.");
      } else if (!(this.cpf.length == 11 || this.cpf.length == 14)) {
        this.errors.push("Informe um CPF válido.");
      }

      if (this.email.length == 0) {
        this.errors.push("Informe o seu E-mail.");
      } else if (!this.validEmail(this.email)) {
        this.errors.push("Informe um e-mail válido");
      }
      if (this.errors.length == 0) {
        let acessoApi = new ApiPortalAutoAtendimento.AcessoApi();

        acessoApi.reDefiniSenhaAcesso(
          this.cpf,
          this.email,
          (error, data, response) => {
            this.processing = false;
            if (!error) {
              this.solicitacaoRealizada = true;
            } else {
              if (response && response.text) {
                try {
                  let listOfErrors = JSON.parse(response.text);
                  listOfErrors.forEach(error => {
                    this.errors.push(error.message);
                  });
                } catch (e) {
                  this.errors.push(
                    "Erro ao solicitar redefinição de senha. Tente novamente."
                  );
                }
              } else {
                this.errors.push(
                  "Erro ao solicitar redefinição de senha. Tente novamente."
                );
              }
            }
          }
        );
      } else {
        this.errors = [];
      }
    },
    validEmail: function(value) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(value);
    }
  }
};
</script>